import { render, staticRenderFns } from "./iframe-page-creation.vue?vue&type=template&id=7eaa199a&scoped=true"
import script from "./iframe-page-creation.vue?vue&type=script&lang=js"
export * from "./iframe-page-creation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7eaa199a",
  null
  
)

export default component.exports